<template>
  <v-snackbar
    v-model="show"
    color="error"
    timeout="-1"
    top
    content-class="text-center"
    data-cy="snack-error"
  >
    <template v-for="(error, i) in errors">
      <p
        :key="i"
        class="error-highlight"
        style="color: black; cursor: pointer; margin-bottom: 8px"
        @click="$emit('errorClick', error.value || null)"
      >
        {{ error.text }}
      </p>
    </template>
    <v-btn
      icon
      small
      @click="$emit('close')"
    >
      <v-icon small>mdi-close</v-icon>
    </v-btn>
  </v-snackbar>
</template>

<script>
export default {
  name: 'MultiErrorsSnackbar',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    errors: {
      type: Array,
      default: () => []
    }
  }
}
</script>