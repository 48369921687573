<template>
  <v-autocomplete
    v-model="value"
    :items="options"
    outlined
    :auto-select-first="autoSelectFirst"
    dense
    :item-text="itemText"
    :item-value="itemValue"
    chips
    :readonly="readonly"
    :deletable-chips="deletableChips"
    small-chips
    :rules="[v => required ? (!!v || 'Required!') : true]"
    :required="required"
    :label="$lang.labels.roles"
    :multiple="multiple"
    :data-cy="dataCy"
    :class="{ 'roles-select': true, 'required-asterisk': required }"
    @change="$emit('changeRoles', value)"
  >
    <template v-slot:item="{ item }">
      <v-list-item-content>
        <v-list-item-title :data-cy="item[itemValue]">{{ item[itemText] }}</v-list-item-title>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  name: 'UserRolesSelect',
  props: {
    options: {
      type: Array,
      default: () => []
    },
    role: {
      type: Array,
      default: () => []
    },
    dataCy: {
      type: String,
      default: () => ''
    },
    autoSelectFirst: {
      type: Boolean,
      default: () => true
    },
    required: {
      type: Boolean,
      default: () => true
    },
    multiple: {
      type: Boolean,
      default: () => true
    },
    deletableChips: {
      type: Boolean,
      default: () => true
    },
    itemText: {
      type: String,
      default: () => 'name'
    },
    itemValue: {
      type: String,
      default: () => 'id'
    },
    readonly: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      value: this.role
    }
  },
  watch: {
    role: {
      handler () {
        this.value = this.role
      }
    }
  }
}
</script>

<style lang="scss">
  .roles-select .v-chip {
    margin: 3px !important;
  }
</style>
