<template>
  <v-card class="pa-2">
    <v-card-title class="pb-2">
      <span>{{ title }}</span>
      <v-spacer></v-spacer>
      <v-btn
        icon
        color="primary"
        text
        large
        @click="$emit('closeDialog', true)"
      >
        X
      </v-btn>
    </v-card-title>
    <v-card-text class="pb-2" style="font-size: 16px !important;">{{ actionText }}</v-card-text>
    <v-row no-gutters justify="space-between">
      <v-btn
        color="error"
        text
        @click="clickOnNo()"
      >
        {{ $lang.actions.no }}
      </v-btn>
      <v-btn
        color="primary"
        text
        @click="$emit('submit', true)"
      >
        {{ $lang.actions.yes }}
      </v-btn>
    </v-row>
  </v-card>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: () => ''
    },
    actionText: {
      type: String,
      default: () => ''
    },
    handleNo: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {}
  },
  methods: {
    clickOnNo () {
      if (this.handleNo) {
        this.$emit('noSelected', true)
      } else {
        this.$emit('closeDialog', true)
      }

    }
  }
}
</script>
<style lang="scss" scoped>
</style>
